import React, { useState, useEffect } from 'react';
import db from '../firebase';
import "./Dates.css"

function Dates(){
    const [dates, setDates] = useState([]);
    const priorityMap = new Map([
        [0, 'low'],
        [1, 'medium'],
        [2, 'high'],
      ]);

    const fetchDates = () => {
        db.collection("dates").get().then((querySnapshot) => {
            querySnapshot.forEach(element => {
                var data = element.data();
                setDates(arr => [...arr , data]);
            });
            setDates(dates => dates.sort((a, b) => a.priority - b.priority))
        });
    }
   
    useEffect(()=>{
        fetchDates();
    }, [])

    return (
        <div className="dates">
            <br/>
            <h2>current date list:</h2>
            {dates.map(date => {
                return <div className='idea'>
                    <div className='choice'
                        style={{backgroundColor: '#DFB9B9'}}>
                        {date.date}
                    </div>
                    <div className='choice'
                        style={{backgroundColor: '#DFB9B9'}}>
                        {priorityMap.get(date.priority)}
                    </div>
                </div>
            })}
        </div>
    )
}

export default Dates