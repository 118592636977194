import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyARxIi31DKC63H_A7eYDetY7-Mw4SeQtko",
  authDomain: "for-katy.firebaseapp.com",
  projectId: "for-katy",
  storageBucket: "for-katy.appspot.com",
  messagingSenderId: "191092154051",
  appId: "1:191092154051:web:70656667a8836322fe0c76"
};
/* eslint-disable no-unused-vars */
const app = firebase.initializeApp(firebaseConfig);
/* eslint-enable no-unused-vars */
const db = firebase.firestore();
export default db;