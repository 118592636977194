import React from 'react';
import Form from './Form';
import Dates from './Dates';
import './Home.css';

function Home(){
    return <div className="home-page">
        <h1>hi i love you very much</h1>
        <Form />
        <Dates />
    </div>;
}

export default Home;