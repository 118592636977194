import React, { useState } from 'react';
import './Form.css'
import db from '../firebase';

let choices = [
    'low',
    'medium',
    'high'
]

function Form(){
    const [date, setDate] = useState('');
    const [selected, setSelected] = useState([false, false, false]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let priority = selected.findIndex(element => element);
        if (date === '') {
            alert('please enter a date idea!!');
            return;
        }

        if (priority === -1) {
            alert('please select a priority!!')
        }

        else {
            try {
                db.collection("dates").add({
                    date: date,
                    priority: priority,
                });
                alert('submitted!! please refresh your page!!')
            } catch (e) {
                console.error("Error adding document");
            }
        }
    }

    const updateSelected = (index) => {
        let new_selected = choices.map(_ => false)
        new_selected[index] = true
        setSelected(new_selected)
    }

    return (
        <form className='form' onSubmit={e => handleSubmit(e)}>
            <label>
                what i want to do:
                <br/>
                <input type="text" value={date} onChange={e => setDate(e.target.value)} />
            </label>
            <>
                priority:
                <br/>
                <div className='choices'>
                    {choices.map((choice, i) => (
                        <div className='choice' key={i} 
                            onClick={() => updateSelected(i)} 
                            style={{backgroundColor: (selected[i] ? '#DF9AB3' : '#DFB9B9') }}>
                            {choice}
                        </div>
                    ))}
                </div>
            </>
            <input type="submit" value="submit" />
        </form>
    )
}

export default Form